import React from 'react'

const Copyright = () => {
  const today = new Date();
  const year = today.getFullYear();

  return ( 
      <div className="copyright">
        TEKOMERAH &copy; {year}
      </div>
   );
}
 
export default Copyright;