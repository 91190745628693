import React from 'react';
import {Helmet} from "react-helmet";
import { graphql, Link } from 'gatsby';
import Page from '../../components/page';
import ProcessCard from '../../components/processCard';
import { pageTitle } from '../../style/layout/_page.module.scss';
import { processContent, processNextStep, buttonWrapper, text}
  from '../../style/layout/_process.module.scss';

const DesignProcess = ({ data }) => {
  return (<>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Our Design Process | Teko Merah</title>
    <meta name="author" content="Reinard Ch"/>
    <meta name="description" content="We cover all your design needs. From surveying the site, design development,
    and construction on site."/>
    <meta name="twitter:title" content="Teko Merah Design Process"></meta>
  </Helmet>
  <Page>
      <div className="process-page page-content">
        <div className={pageTitle}>DESIGN PROCESS</div>
        <div className={processContent}>
          {data.allProcessJson.nodes.map((data) => {
            return (
              <ProcessCard key={data.id} {...data}/>
            )
          })}
        </div>
        <div className={processNextStep}>
          <span className={text}>Ready to work with us?</span>
          <div className={buttonWrapper}>
            <Link to="/contact"><button>CONTACT US</button></Link>
            <Link to="/projects"><button>OUR PAST PROJECTS</button></Link>
          </div>
        </div>
      </div>
    </Page>
  </>);
}

export const query = graphql`
  query{
    allProcessJson {
      nodes {
        id
        title
        content
        icon {
          src {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
          }
          alt
        }
      }
    }
  }`;
  
export default DesignProcess;