import React from 'react'
import Sidebar from './sidebar'
import {pageWrapper} from '../style/layout/_page.module.scss'
import Copyright from './copyright';

const Page = ({children}) => {
  return (
  <main>
    <div className={pageWrapper}>
      {children}
    </div>
    <Copyright/>
    <Sidebar/>
  </main>
  );
}
 
export default Page;