import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';
import React, {useState, useRef} from 'react';
import Logo from '../components/logo';
import LogoBare from './logoBare';


const Sidebar = () => {
  const today = new Date();
  const sidebarRef = useRef();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const homepage = {
    url: '/'
  }
  const links = [
    {text: 'Projects', url:'/projects'},
    {text: 'About', url:'/about'},
    {text: 'Contact', url:'/contact'},
  ]

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    if(toggleSidebar) {
      //close it
      sidebarRef.current.style.left = "100vw";
      setToggleSidebar(!toggleSidebar);
    } else {
      //open it
      sidebarRef.current.style.left = "0";
      setToggleSidebar(!toggleSidebar);
    }
  }

  const handleNavigate = (e, link) => {
    //close sidebar
    sidebarRef.current.style.left = "100vw";
    setToggleSidebar(!toggleSidebar);
    //navigate
    setTimeout(()=> {navigate(link.url)}, 650);
  }

  return ( 
  <>
    <div className="sidebar-head">
      <LogoBare size={'2rem'}/>
      <button className="btn btn-sidebar" onClick={(e)=>{handleToggleSidebar(e)}}>
        <FontAwesomeIcon icon={faBars}/>
      </button>
    </div>

  <div className="sidebar" ref={sidebarRef}>
    <button className="btn btn-sidebar-close" onClick={(e)=>{handleToggleSidebar(e)}}>
      <FontAwesomeIcon icon={faTimes}/>
    </button>
    <div className="sidebar-logo">
      <div>
        <Link to="/">
          <Logo size={'3rem'}/>
        </Link>
      </div>
    </div>
    <div className="sidebar-links">
      <ul>
        {links.map((link, index) => {
          return (
            <li key={index}>
              <Link to={link.url}>{link.text}</Link>
            </li>
          )
        })}
      </ul>
    </div>
    <div className="sidebar-copyright">
      Tekomerah &copy; {today.getFullYear()}
    </div>
  </div>
  </>
  );
}
 
export default Sidebar;