import React from 'react'

const LogoBare = ({size}) => {
  return ( <div style={{height: size, width: size}}>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 300 300" enableBackground="new 0 0 300 300">
    <g>
     <path fill="#CA3332" d="M298.2,171.8L298.2,171.8c-9.1-9.1-12-22.4-7.7-34.9c1.3-3.6,2-7.4,2.2-11.3c0.6-11.5-3.4-22.4-11.3-30.8
     c-8-8.5-19.4-13.4-31.2-13.4h-2.3c-19.3-30.4-53.2-50.7-91.8-50.7c-59.9,0-108.6,48.7-108.6,108.6c0,26.1,9.2,50,24.6,68.8
     c-19.4-5.3-33.3-23.3-33.3-45.2v-49.4c0-17.7-14.4-32.1-32.1-32.1H4.8c-2.6,0-4.8,2.1-4.8,4.8s2.1,4.8,4.8,4.8h1.8
     c12.4,0,22.5,10.1,22.5,22.5V163c0,31.1,23.4,55.6,53.4,56.4c19.4,17.8,45.2,28.7,73.5,28.7c59.9,0,108.6-48.7,108.6-108.6
     c0-17.3-4.1-33.6-11.3-48.2c8,0.8,15.6,4.4,21.1,10.2c6.1,6.4,9.2,14.9,8.7,23.7c-0.2,3-0.7,5.9-1.7,8.7c-5.5,16-1.7,33.1,9.9,44.7
     l0.1,0.1c0.9,0.9,2.2,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4C300.1,176.7,300.1,173.7,298.2,171.8z M156,238.5c-54.6,0-99-44.4-99-99
     c0-54.6,44.4-99,99-99c54.6,0,99,44.4,99,99C255,194,210.6,238.5,156,238.5z"/>
     <g>
    
       <path fill="#CA3332" stroke="#CA3332" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
       M153.6,139.4"/>
     </g>
     <g>
     <circle fill="#CA3332" cx="156" cy="12.6" r="12.6"/>
     </g>
    </g>
    </svg>
  </div> );
}
 
export default LogoBare;