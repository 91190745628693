// extracted by mini-css-extract-plugin
export var processCard = "_processcard-module--process-card--1YVUN";
export var arrow = "_processcard-module--arrow--3iGE-";
export var icon = "_processcard-module--icon--l0E0E";
export var processInfo = "_processcard-module--process-info--3sl9W";
export var dskProcessCard = "_processcard-module--dsk-process-card--3OfxH";
export var dskIconWrapper = "_processcard-module--dsk-icon-wrapper--1Z_EG";
export var dskIcon = "_processcard-module--dsk-icon--12SRy";
export var dskProcessInfo = "_processcard-module--dsk-process-info--3u1Be";
export var dskProcessContent = "_processcard-module--dsk-process-content--mkoFf";
export var dskArrow = "_processcard-module--dsk-arrow--1Ea3a";
export var nudging = "_processcard-module--nudging--1B5k1";