import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { processCard, icon, processInfo, arrow}
  from '../style/components/_processcard.module.scss';

const ProcessCard = ({...props}) => {
  const { title, content } = props;
  const thisIcon = getImage(props.icon.src.childImageSharp.gatsbyImageData);
  const iconAlt = props.icon.alt;
  return ( 
    <div className={processCard}>
      <div className={icon}>
        <GatsbyImage
        image={thisIcon}
        alt={iconAlt}/>
      </div>
      <div className={processInfo}>
        <h3 className="process-card-title">{title}</h3>
        <p className="process-card-content">{content}</p>
      </div>
      {props.title !== "Fabrication & Installation" ? <div className={arrow}>
        <FontAwesomeIcon icon={faArrowDown}/>
      </div> :
      <div></div>}
    </div>
   );
}
 
export default ProcessCard;